<template>
  <el-drawer title="美妆服饰详情" :visible.sync="isDrawer" :with-header="false" size="86%" :before-close="handleClose"
    :modal-append-to-body="false" :wrapperClosable="false" :close-on-press-escape="false">
    <div class="h-full flex flex-dc" v-if="isDrawer">
      <div class="drawer_title pall-30 flex flex-jb flex-n">
        <div class="fs-18 fw-bold">美妆服饰详情</div>
        <i class="el-icon-close fs-20 pointer" @click="handleClose"></i>
      </div>
      <div class="pl-40 flex-1 overflow-scroll-y">
        <t-simple-form :ref-obj.sync="formOpts.ref" :formOpts="formOpts" :widthSize="4" @handleEvent="handleEvent"
          class="drawer_form">
          <!-- 平台自定义插槽 -->
          <template #goodDetailsList>
            <div class="pos-s t-0 bgc-fff zi-10">
              <div class="pb-20 flex flex-ac flex-jb">
                <div class="fs-14 fw-bold flex-1">
                  <span class="h-10 w-5 bgc-38393b mr-10 dis-in-block"></span>
                  <span>系列商品</span>
                </div>
                <div class="flex flex-ac">
                  <div class="mr-30">数量：{{formOpts.formData.goodDetailsList.length}}</div>
                  <el-button type="primary" @click="addDomain">新增</el-button>
                </div>
              </div>
            </div>
            <div v-for="(domains,index) in formOpts.formData.goodDetailsList" class="flex flex-ac mb-20" :key="index">
              <el-checkbox v-model="domains.isPresell" :true-label="1" :false-label="2">是否预售</el-checkbox>
              <el-form-item :label="'商品名称'" label-position="right" label-width="140px"
                :prop="'goodDetailsList.' + index + '.ductName'"
                :rules="{required: true, message: '商品名称不能为空', trigger: 'blur'}" class="flex flex-ac">
                <el-input v-model="domains.ductName" type="text" clearable></el-input>
              </el-form-item>
              <el-form-item label="分类" label-position="right" label-width="140px"
                :prop="'goodDetailsList.' + index + '.detailsType'"
                :rules="{required: true, message: '请至少选择一个分类', trigger: 'change'}" class="flex flex-ac">
                <el-select v-model="domains.detailsType" clearable placeholder="请选择">
                  <el-option :key="0" label="鞋子" :value="0" v-if="domains.gradeConsumeId == 12"></el-option>
                  <el-option v-for="item in detailsTypes" :key="item.value" :label="item.key" :value="item.value"
                    v-else>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="商品等级" label-position="right" label-width="140px"
                :prop="'goodDetailsList.' + index + '.gradeConsumeId'"
                :rules="{required: true, message: '请至少选择一个商品等级', trigger: 'blur'}" class="flex flex-ac"
                v-if="!testFlag">
                <el-select v-model="domains.gradeConsumeId" clearable placeholder="请选择">
                  <el-option :key="12" label="终赏" :value="12" v-if="domains.levelType == 12"></el-option>
                  <el-option v-for="item in gradeConsumes" :key="item.id" :label="item.grade" :value="item.id" v-else>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="库存数量" label-position="right" label-width="140px"
                :prop="'goodDetailsList.' + index + '.inventory'"
                :rules="{required: true, message: '库存不能为空', trigger: 'blur'}" class="flex flex-ac">
                <el-input v-model="domains.inventory" type="number" clearable></el-input>
              </el-form-item>
              <el-form-item label="参考价格" label-position="right" label-width="140px"
                :prop="'goodDetailsList.' + index + '.referPrice'"
                :rules="{required: true, message: '参考价格不能为空', trigger: 'blur'}" class="flex flex-ac">
                <el-input v-model="domains.referPrice" type="number" clearable></el-input>
              </el-form-item>
              <el-form-item label="参考等级" label-position="right" label-width="140px"
                :prop="'goodDetailsList.' + index + '.levelType'"
                :rules="{required: true, message: '请至少选择一个参考等级', trigger: 'change'}" class="flex flex-ac">
                <el-select v-model="domains.levelType" clearable placeholder="请选择">
                  <el-option :key="12" label="LAST" :value="12" v-if="domains.gradeConsumeId == 12"></el-option>
                  <el-option v-for="item in levelTypes" :key="item.value" :label="item.key" :value="item.value" v-else>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="商品图片" label-position="left" label-width="140px"
                :prop="'goodDetailsList.' + index + '.boxImg'"
                :rules="{required: true, message: '图片不能为空', trigger: 'blur'}" class="flex flex-ac">
                <upload-file upStyle="width: 50px; height: 50px; border-radius: 5px; overflow: hidden;"
                  iconName="el-icon-upload" iconSize="16" v-model="domains.boxImg"></upload-file>
              </el-form-item>

              <el-button type="primary" plain @click.prevent="removeDomain(index)" class="ml-20"
                v-if="formOpts.formData.goodDetailsList.length > 1">删除
              </el-button>

            </div>
          </template>
          <!-- 上传图片 -->
          <template #uploadFile="data">
            <upload-file upStyle="width: 80px; height: 80px; border-radius: 5px; overflow: hidden;"
              iconName="el-icon-upload" iconSize="26" v-model="formOpts.formData[data.value]"></upload-file>
          </template>
        </t-simple-form>
      </div>
      <div class="drawer_button pall-30 flex-n flex flex-ac flex-je">
        <el-button type="primary" @click="submitForm" :loading="butLoading">{{goodId?'保存':'提交'}}</el-button>
        <el-button type="primary" plain @click="handleClose">取消</el-button>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" append-to-body>
      <span>您确认展示{{formOpts.formData.showPage}}套吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editOkSubmit">确 定</el-button>
      </span>
    </el-dialog>

  </el-drawer>
</template>

<script>
import {
  mapState
} from 'vuex'
export default {
  name: 'rewardDetails',
  components: {
    "TSimpleForm": (resolve) => require(["@/components/public/TSimpleForm"], resolve),
    "UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
  },
  computed: {
    ...mapState('menu', {
      'levelTypes': state => state.dictList ? state.dictList.level_type : [], //系列分类
      'detailsTypes': state => state.dictList ? state.dictList.details_type : [], //系列分类
    })
  },
  //  mounted () {
  // // // seriesPattern   formOpts formData
  // if(this.formOpts.listTypeInfo.sericeTypeOptions[0].value == 1){
  // 	this.isSeriesType = true
  // } else {
  // 	this.isSeriesType = false
  // }
  //  },
  data () {
    return {
      dialogVisible: false, //确认展示套数，提示弹框
      goodId: '', //详情id
      isDrawer: false, //详情弹框
      isSeriesType: false, //控制增加秒数的时间显示
      butLoading: false,
      formOpts: {
        labelPosition: 'top',
        ref: null,
        formData: {
          seriesName: '', //系列名称
          goodSeriesType: '', //系列分类
          shelves: '', //是否上架
          price: '', //原价
          soldPrice: '', //售出价格
          boxNum: '', //盒子数量
          deliverDate: '', //发货日期
          totalPage: '5', //总的套数
          showPage: '5', //展示的套数
          money: '10', //回收优惠金余额
          dateDay: '365', //有效期
          recycleBlance: '0', //回收金额
          seriesPattern: '', //系列状态
          coverImg: '', //封面图
          entireImg: '', //全家福图片
          detailImg: '', //详情图
          id: '', //系列id
          note: '', //玩法说明
          sort: '', //排序
          buySec: 20,
          goodDetailsList: [{
            ductName: '', //商品名称
            gradeConsumeId: '', //实际等级
            referPrice: '', //参考价格(前端显示)
            inventory: '', //	库存
            levelType: 12, //前端展示等级
            boxImg: '', //商品图片
            isPresell: 2, //是否预售  1预售  2现货
            detailsType: ''
          }], //商品信息
        },
        fieldList: [{
          label: '系列名称',
          value: 'seriesName',
          comp: 'el-input'
        },
        {
          label: '分类',
          value: 'goodSeriesType',
          type: 'select-arr',
          comp: 'el-select',
          list: 'goodSeriesTypes',
          bind: {
            disabled: false
          },
          arrLabel: 'key',
          arrKey: 'value'
        },
        {
          label: '上下架售罄',
          value: 'shelves',
          type: 'select-arr',
          comp: 'el-select',
          list: 'shelves',
          arrLabel: 'key',
          arrKey: 'value'
        },
        {
          label: '发货日期',
          value: 'deliverDate',
          type: 'date',
          comp: 'el-date-picker',
          bind: {
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd"
          }
        },
        {
          label: '原价',
          value: 'price',
          type: 'number',
          comp: 'el-input',
        },
        {
          label: '售出价格',
          value: 'soldPrice',
          type: 'number',
          comp: 'el-input'
        },
        {
          label: '系列状态',
          value: 'seriesPattern',
          type: 'select-arr',
          comp: 'el-select',
          list: 'sericeTypeOptions',
          arrLabel: 'key',
          arrKey: 'value',
          event: 'seriesPattern'
        },
        {
          label: '回收鸭币',
          value: 'recycleBlance',
          type: 'number',
          comp: 'el-input',
          isHideItem: () => {
            return !this.testFlag
          }
        },
        {
          label: '回收优惠金',
          value: 'money',
          type: 'number',
          comp: 'el-input',
          isHideItem: () => {
            return !this.testFlag
          }
        },
        {
          label: '有效期',
          value: 'dateDay',
          type: 'number',
          comp: 'el-input',
          isHideItem: () => {
            return !this.testFlag
          }
        },
        {
          label: '总的套数',
          value: 'totalPage',
          type: 'number',
          comp: 'el-input',
          bind: {
            disabled: true
          },
        },
        {
          label: '展示的套数',
          value: 'showPage',
          type: 'number',
          comp: 'el-input',
          event: 'showPage'
        },
        {
          label: '排序',
          value: 'sort',
          type: 'number',
          comp: 'el-input'
        },

        {
          label: '增加时间（s）',
          value: 'buySec',
          type: 'number',
          comp: 'el-input',
          isHideItem: () => {
            return this.isSeriesType
          }
        },
        {
          label: '玩法说明',
          value: 'note',
          type: 'textarea',
          comp: 'el-input',
          className: 't-form-block'
        },
        {
          label: '封面图',
          value: 'coverImg',
          slotName: 'uploadFile'
        },
        {
          label: '详情图',
          value: 'detailImg',
          slotName: 'uploadFile'
        },
        {
          label: '全家福图片',
          value: 'entireImg',
          slotName: 'uploadFile'
        },
        {
          label: '',
          slotName: 'goodDetailsList',
          className: 't-form-block'
        },
        ],
        rules: {
          seriesName: [{
            required: true,
            message: '请输入系列名称',
            trigger: 'blur'
          }],
          shelves: [{
            required: true,
            message: '请至少选择一个状态',
            trigger: 'change'
          }],
          seriesPattern: [{
            required: true,
            message: '请至少选择一个系列状态',
            trigger: 'change'
          }],
          goodSeriesType: [{
            required: true,
            message: '请至少选择一个分类',
            trigger: 'change'
          }],
          deliverDate: [{
            required: true,
            message: '请选择发货日期',
            trigger: 'blur'
          }],
          price: [{
            required: true,
            message: '请输入价格',
            trigger: 'blur'
          }],
          soldPrice: [{
            required: true,
            message: '请输入现价',
            trigger: 'blur'
          }],
          totalPage: [{
            required: true,
            message: '请输入总套数',
            trigger: 'blur'
          }],
          showPage: [{
            required: true,
            message: '请输入展示的套数',
            trigger: 'blur'
          }],
          money: [{
            required: true,
            message: '请输入回收优惠金额度',
            trigger: 'blur'
          }],
          dateDay: [{
            required: true,
            message: '请输入优惠金有效天数',
            trigger: 'blur'
          }],
          recycleBlance: [{
            required: true,
            message: '请输入回收鸭币数量',
            trigger: 'blur'
          }],
        },
        //按钮
        operatorList: [],
        // 相关列表
        listTypeInfo: {
          goodSeriesTypes: this.$store.state.menu.dictList.good_series_type,
          shelves: this.$store.state.menu.dictList.shelves,
          sericeTypeOptions: [{
            value: 1,
            key: '排队'
          }, {
            value: 0,
            key: '竞技'
          }],
        }
      },
      gradeConsumes: [], //真实等级
      testFlag: false, //测试
    }
  },
  created () {
    //测试人员
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo.name == '测试') {
      this.testFlag = true
    }
  },
  mounted () { },
  methods: {
    feng (e) {
      console.log("e", e)
    },
    // 初始化
    init (id) {
      var that = this
      that.isDrawer = true
      that.goodId = id
      this.isSeriesType = false
      this.findGrade()
      if (id) {
        that.getDetails(id)
      }
    },
    //一番赏商品真实等级
    findGrade () {
      this.$http.get('/grade/findGrade').then(({
        data: result
      }) => {
        this.gradeConsumes = result.data
      })
    },
    //关闭弹框
    handleClose () {
      this.isDrawer = false
      this.resetForm()
    },
    //获取详情数据
    getDetails (id) {
      this.$http.get(`/goodSeries/findGoodSeriesById?id=${id}`).then(({
        data: res
      }) => {
        let goodData = res.data
        if (goodData.goodDetailsList == null || goodData.goodDetailsList == undefined || goodData
          .goodDetailsList == "") {
          goodData.goodDetailsList = []
        }
        this.isSeriesType = goodData.seriesPattern == 1 ? true : false
        this.formOpts.formData = goodData
      })
    },
    // 触发事件
    handleEvent (type, val) {
      switch (type) {
        case 'checkbox':
          console.log(val, type)
          break
        case 'showPage':
          if (!this.goodId) {
            this.formOpts.formData.totalPage = val
          }
          break
        case 'seriesPattern':
          if (val == 1) {
            this.isSeriesType = true
            this.formOpts.formData.buySec = 20
          } else {
            this.isSeriesType = false
            this.formOpts.formData.buySec = 0
          }
          break
      }
    },
    // 新增商品
    addDomain () {
      let list = this.formOpts.formData.goodDetailsList
      let levelType = 12
      if (list.length == 1) {
        levelType = 17
      } else if (list.length > 1) {
        levelType = list[list.length - 1].levelType + 1
      }
      this.formOpts.formData.goodDetailsList.push({
        ductName: '', //商品名称
        gradeConsumeId: '', //实际等级
        referPrice: '', //参考价格(前端显示)
        inventory: '', //	库存
        levelType: levelType, //前端展示等级
        boxImg: '', //商品图片
        isPresell: 2, //是否预售  1预售  2现货\
        detailsType: ''
      })
    },
    removeDomain (index) {
      this.formOpts.formData.goodDetailsList.splice(index, 1)
    },
    //提示后最终提交表单
    editOkSubmit () {
      this.dialogVisible = false
      let goodData = JSON.parse(JSON.stringify(this.formOpts.formData))
      goodData.json = JSON.stringify(goodData.goodDetailsList)
      delete goodData.goodDetailsList
      let apiUrl = ''
      if (goodData.id) { //修改
        apiUrl = '/goodSeries/editGoodSeries'
      } else { //新增
        // apiUrl = '/goodSeries/saveGoodSeries'
        // console.log("1",e);
        apiUrl = '/goodSeries/saveGoodSeries?seriesGoodsType=' + 1
      }
      this.$http.post(apiUrl, goodData).then(res => {
        this.$message({
          message: goodData.id ? '修改成功' : '新增成功',
          type: 'success'
        })
        this.butLoading = false
        this.$emit('refresh')
        this.handleClose()
      }).catch(err => {
        this.butLoading = false
      })
    },
    // 提交form表单
    submitForm () {
      this.formOpts.ref.validate((valid) => {
        if (valid) {
          this.butLoading = true
          console.log(77, this.formOpts.formData)
          if (!valid) return
          console.log('最终数据', this.formOpts.formData)
          if (this.formOpts.formData.showPage > 5) {
            this.dialogVisible = true
            return
          } else {
            this.editOkSubmit()
          }

        }
      })
    },
    // 重置form表单
    resetForm () {
      Object.assign(
        this.$data.formOpts.formData,
        this.$options.data.call(this).formOpts.formData
      )
      this.$nextTick(() => {
        this.formOpts.ref.clearValidate()
      })
    },
  }
}
</script>

<style lang="less" scoped>
.flexs {
  width: 100%;
}
</style>